<template>
  <div class="custom-date-picker" @click="openFlatpickr">
    <div class="fs-body-16" :class="{'placeholder': formattedDate === placeholder}" v-text="formattedDate"  @click="openFlatpickr"></div>
    <flat-pickr class="d-none"
                ref="datePicker"
                v-model="inputValue"
                :config="config"
                @on-close="closeFlatpickr"
    >
    </flat-pickr>
    <div class="flatpickr-shadow-bg custom-z-index"></div>
  </div>
</template>

<script>
import moment from "moment";
import 'moment/locale/zh-cn';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { MandarinTraditional } from 'flatpickr/dist/l10n/zh-tw';

export default {
  name: 'CustomDatePicker',
  data() {
    return {
      config: {
        minDate: this.minDate,
        maxDate: this.maxDate,
        dateFormat: this.dateFormat,
        locale: MandarinTraditional,
        disableMobile: "true",
      },
    };
  },
  props: {
    value: {
      required: false,
      default: '',
    },
    minDate: {
      required: false,
      default: '',
    },
    maxDate: {
      required: false,
      default: '',
    },
    dateFormat: {
      required: false,
      default: 'Y-m-d',
    },
    placeholder: {
      required: false,
      default: '',
    },
  },
  components: {
    flatPickr,
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    formattedDate() {
      if (this.inputValue) {
        const momentDate = moment(this.inputValue);
        return momentDate.format('YYYY-MM-DD');
      }
      return this.placeholder;
    },
  },
  mounted() {
    moment.locale('zh-cn');
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    openFlatpickr() {
      this.$refs.datePicker.fp.open();
    },
    closeFlatpickr() {
      this.$emit('close');
    },
    handleClickOutside(event) {
      console.log(event)
      // const datePicker = this.$refs.datePicker.$el;
      // const shadowBg = this.$el.querySelector('.flatpickr-shadow-bg');
      //
      // if (datePicker && !datePicker.contains(event.target) && !shadowBg.contains(event.target) && !this.$el.contains(event.target)) {
      //   this.$refs.datePicker.fp.open();
      // }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.custom-date-picker {
  .placeholder {
    color: $color-bk-tp-50;
  }
  .flatpickr-input.active~.flatpickr-shadow-bg {
    display: block;
    width: 100%;
    height: 100%;
    background: $color-black;
    opacity: .8;
  }
  .flatpickr-shadow-bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.custom-date-picker {
}
.form-datepicker.pickup {
  .flatpickr-input {
    width: 100%;
    border: 1px solid $color-black-300;
    border-radius: 4px;
    padding: 6px 12px;
  }
}
.flatpickr-calendar.animate {
  position: fixed;
  top: calc(50% - 150px)!important;
  left: calc(50% - 150px)!important;
  z-index: 1001;
  width: 300px;
  padding-bottom: 8px;
  &:before, &:after {
    display: none;
  }
  .flatpickr-weekdaycontainer {
    padding: 0 8px;
  }
  .flatpickr-days {
    width: 300px;
  }
  .dayContainer {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    padding: 0 8px;
  }
  .flatpickr-day {
    width: 40px;
    max-width: 40px;
    height: 40px;
    border: 2px solid transparent;
    &.today {
      border-color: $color-primary-darkyellow;
    }
    &.selected, &.startRange, &.endRange, &.selected.inRange, &.startRange.inRange, &.endRange.inRange, &.selected:focus, &.startRange:focus, &.endRange:focus, &.selected:hover, &.startRange:hover, &.endRange:hover, &.selected.prevMonthDay, &.startRange.prevMonthDay, &.endRange.prevMonthDay, &.selected.nextMonthDay, &.startRange.nextMonthDay, &.endRange.nextMonthDay {
      color: $color-black;
      background: $color-primary-darkyellow;
      border-color: $color-primary-darkyellow;
    }
  }
}
</style>
