<template>
  <div class="layout-main journey-detail-member-join">
    <ValidationObserver ref="observer" class="path" tag="section">
      <ColumnLayout :routerName="$route.name">
        <template v-slot:column-one>
          <div class="trail-fixed-nav" ref="fixedNav">
            <div class="trail-block mb-0">
              <div class="trail-name fs-h5 text-center" v-text="journeyData?.name"></div>

              <div class="trail-days pb-0">
                <div class="fs-body-14 text-center" v-text="journeyDurationString"></div>
              </div>
            </div>
          </div>

          <div v-if="!isOwner" class="trail-create-btn-group bottom-btn">
            <button class="btn common-setting" @click="cancelClicked" id="joinCancel">
              <span>取消</span>
            </button>
            <button class="btn common-setting dark" @click="sendJoinSubmit" id="joinConfirm">
              <span>確定</span>
            </button>
          </div>

          <CustomMessageDialog :dialogData="joinFlowData" :autoClose="true" @close="closeAvailableDialog" />

          <CustomMessageDialog :dialogData="successData" :autoClose="true" @close="closeSuccessDialog" />

        </template>
        <template v-slot:column-two>
          <div class="trail-application-nav d-flex justify-content-center">
            <div class="trail-joining-block">
              <h2 class="fs-h5 mb-2 text-center">{{ isJoined ? '編輯入團資料' : '加入行程' }}</h2>
              <p class="fs-body-14 text-center mb-2">
                {{ (isJoined ? '請修改' : '需填寫') + '下方資料提供主辦人協助辦理入山(園)證。' }}
              </p>
              <p class="attention-text text-center fs-body-14">
                請您放心，您的個人資料僅會提供於主辦人辦理此次活動相關事宜，且資料將於活動開始日當天移除，不會永久保存。
              </p>
            </div>
          </div>
          <div class="trail-team-group join">
            <div class="trail-joining-block">
              <ValidationProvider ref="nameValidation" tag="div" rules="required" v-slot="{ errors }" class="form-group">
                <label for="realName" class="form__label fs-title-14">真實姓名</label>
                <input id="realName" type="text" class="form__input fs-body-16" v-model="joinSubmit.realName" placeholder="請輸入真實姓名">
                <div class="form__error-msg fs-capation-12" v-if="errors.length > 0">{{ errors[0] }}</div>
              </ValidationProvider>
              <ValidationProvider ref="emailValidation" tag="div" rules="required|email" v-slot="{ errors }" class="form-group">
                <label for="email" class="form__label fs-title-14">Email</label>
                <input id="email" type="text" class="form__input fs-body-16" v-model="joinSubmit.email" placeholder="請輸入Email">
                <div class="form__error-msg fs-capation-12" v-if="errors.length > 0">{{ errors[0] }}</div>
              </ValidationProvider>
              <ValidationProvider ref="phoneValidation" tag="div" rules="required" v-slot="{ errors }" class="form-group">
                <label for="phone" class="form__label fs-title-14">電話</label>
                <input id="phone" type="text" class="form__input fs-body-16" v-model="joinSubmit.phone" placeholder="請輸入電話">
                <div class="form__error-msg fs-capation-12" v-if="errors.length > 0">{{ errors[0] }}</div>
              </ValidationProvider>
              <ValidationProvider ref="countryValidation" tag="div" rules="required" v-slot="{ errors }" class="form-group">
                <label for="countyCode" class="form__label fs-title-14">縣市</label>
                <b-form-select
                    id="countyCode"
                    :class="{'value-null': countyCode === ''}"
                    v-model="countyCode"
                    :options="countyList"
                    value-field="countycode01"
                    text-field="countyname"
                    @change="updateCityList"
                >
                  <template #first>
                    <b-form-select-option value="" disabled>請選擇縣市</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="form__error-msg fs-capation-12" v-if="errors.length > 0">{{ errors[0] }}</div>
              </ValidationProvider>
              <ValidationProvider ref="cityValidation" tag="div" rules="required" v-slot="{ errors }" class="form-group" :class="{ 'form-group--error': submitStatus === 'INVALID' && $v.inputCityCode.$error }">
                <label for="cityCode" class="form__label fs-title-14">區域</label>
                <b-form-select
                    id="cityCode"
                    :class="{'value-null': joinSubmit.cityCode === ''}"
                    v-model="joinSubmit.cityCode"
                    :options="cityList"
                    value-field="towncode"
                    text-field="townname"
                >
                  <template #first>
                    <b-form-select-option value="" disabled>請選擇區域</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="form__error-msg fs-capation-12" v-if="errors.length > 0">{{ errors[0] }}</div>
              </ValidationProvider>
              <ValidationProvider ref="addressValidation" tag="div" rules="required" v-slot="{ errors }" class="form-group">
                <label for="address" class="form__label fs-title-14">地址</label>
                <input id="address" type="text" class="form__input fs-body-16" v-model="joinSubmit.address" placeholder="請輸入聯絡地址">
                <div class="form__error-msg fs-capation-12" v-if="errors.length > 0">{{ errors[0] }}</div>
              </ValidationProvider>
              <ValidationProvider ref="personalIdValidation" tag="div" rules="required" v-slot="{ errors }" class="form-group">
                <label for="personalId" class="form__label fs-title-14">身分證字號</label>
                <input id="personalId" type="text" class="form__input fs-body-16" v-model="joinSubmit.personalId" placeholder="請輸入身分證字號">
                <div class="form__error-msg fs-capation-12" v-if="errors.length > 0">{{ errors[0] }}</div>
              </ValidationProvider>
              <ValidationProvider ref="birthdayValidation" tag="div" rules="required" v-slot="{ errors }" class="form-group">
                <label class="form__label fs-title-14">生日</label>
                <CustomDatePicker
                    class="form-birthday"
                    ref="birthdayDatePicker"
                    maxDate="today"
                    v-model="joinSubmit.birthday"
                    :placeholder="'請輸入生日'"
                />
                <div class="form__error-msg fs-capation-12" v-if="errors.length > 0">{{ errors[0] }}</div>
              </ValidationProvider>
              <ValidationProvider ref="emergencyNameValidation" tag="div" rules="required" v-slot="{ errors }" class="form-group">
                <label for="emergencyName" class="form__label fs-title-14">緊急聯絡人姓名</label>
                <input id="emergencyName" type="text" class="form__input fs-body-16" v-model="joinSubmit.emergencyName" placeholder="請輸入緊急聯絡人姓名">
                <div class="form__error-msg fs-capation-12" v-if="errors.length > 0">{{ errors[0] }}</div>
              </ValidationProvider>
              <ValidationProvider ref="emergencyPhoneValidation" tag="div" rules="required" v-slot="{ errors }" class="form-group">
                <label for="emergencyPhone" class="form__label fs-title-14">緊急連絡人電話</label>
                <input id="emergencyPhone" type="text" class="form__input fs-body-16" v-model="joinSubmit.emergencyPhone" placeholder="請輸入緊急連絡人電話">
                <div class="form__error-msg fs-capation-12" v-if="errors.length > 0">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
          </div>
        </template>
      </ColumnLayout>
    </ValidationObserver>
  </div>
</template>

<script>
import moment from "moment";
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import {mapState} from "vuex";
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import ColumnLayout from '@/components/common/ColumnLayout';
import CustomMessageDialog from "@/views/common/CustomMessageDialog.vue";
import CustomDatePicker from "@/components/common/CustomDatePicker.vue";

extend('required', {
  ...required,
  message: '此為必填欄位。',
});
extend('email', {
  ...email,
  message: '請填寫正確格式的email。',
});

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'GroupDetailMemberJoin',
  data() {
    return {
      readInfoBusyName: 'GroupDetailMemberJoin',

      pageId: null,
      groupData: null,
      journeyData: null,
      joinedOwner: {},
      joinedMembers: [],
      isJoined: false,

      submitStatus: null,
      joinSubmit: {
        groupSerial: -1,
        lineId: '',
        realName: '',
        phone: '',
        email: '',
        cityCode: '',
        address: '',
        personalId: '',
        birthday: null,
        emergencyName: '',
        emergencyPhone: '',
        fullAddress: '',
      },
      countyList: [],
      cityList: [],
      countyCode: '',
      joinFlowData: {
        open: false,
        message: 'oops...該團已經額滿或不開放參加。',
        type: 'warning',
      },
      successData: {
        open: false,
        message: '加入成功！',
        type: 'success',
      },
    };
  },
  props: {
  },
  components: {
    ColumnLayout,
    ValidationObserver,
    ValidationProvider,
    CustomMessageDialog,
    CustomDatePicker,
  },
  computed: {
    ...mapState(['user']),

    isOwner() {
      return this.user && this.user.serial === this.groupData?.groupMasterSerial;
    },
    isExpired: function() {
      const theDayAfterEndDate = moment(this.journeyData.endDate);
      theDayAfterEndDate.add(1, 'day');
      return moment().isAfter(theDayAfterEndDate);
    },
    journeyDurationString: function() {
      if(this.journeyData) {
        let str = moment(this.journeyData.startDate).format('YYYY/MM/DD(dd)');
        if (this.journeyData.durationDays > 1) {
          str += ' ~ ' + moment(this.journeyData.endDate).format('YYYY/MM/DD(dd)');
        }
        return str;
      } else {
        return '';
      }
    },
    groupJoinFlow() {
      return !(this.groupData?.joinFlow === 0 || this.isExpired);

      // switch (this.groupData?.joinFlow) {
      //   case 0:
      //     return false;
      //   case 1:
      //     return true;
      //   default:
      //     return true;
      // }
    },
	},
  watch: {
    countyCode() {
      this.refreshCityCode();
      this.updateFullAddress();
    },
    'joinSubmit.cityCode'() {
      this.updateFullAddress();
    },
    'joinSubmit.address'() {
      this.updateFullAddress();
    }
  },
  async mounted() {
    this.pageId = this.$route.params.id;
    await this.refresh(this.pageId);
	},
  methods: {
    async refresh(journeyId) {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        this.groupData = await this.$store.dispatch('api/getGroupDetailPromise', journeyId);
        this.joinSubmit.groupSerial = this.groupData?.serial;
        this.journeyData = await this.$store.dispatch('api/getJourneyDetailPromise', this.groupData?.journeys[0]);
        this.joinedOwner = await this.$store.dispatch('api/getUserDataPromise', this.groupData?.groupMasterSerial);
        this.countyList = await this.$store.dispatch('api/getCountryListPromise');
        const data = await this.$store.dispatch('api/postGroupTestIsJoinedPromise', this.groupData?.serial);
        this.isJoined = data.isJoined;
        if(this.isJoined) {
          const memberData = await this.$store.dispatch('api/getGroupMembersPromise', this.groupData?.serial);
          console.log(memberData)
          if(memberData.groupList !== null) {
            memberData.groupList.map((item) => {
              if (item.userSerial === this.user.serial) {
                Object.keys(this.joinSubmit).forEach(key => {
                  if (item[key] !== undefined) {
                    this.joinSubmit[key] = item[key];
                  }
                });
              }
            })
          }
          this.countyCode = this.joinSubmit.cityCode.substring(0, 5);
          this.successData.message = '更新成功！';
        } else {
          this.joinFlowData.open = !this.groupJoinFlow;
        }
        await this.updateCityList();
      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    cancelClicked() {
      this.$router.push({ name: 'GroupDetail' });
    },
    async sendJoinSubmit() {
      const success = await this.$refs.observer.validate();
      if (success) {
        console.log('Validation successful');
        await this.handleAsyncTask(async () => {
          // await this.refresh(this.pageId);
          // if(this.groupJoinFlow) {
          //   const data = await this.$store.dispatch('api/postGroupJoinPromise', this.joinSubmit)
          //   console.log(data)
          // }
          if(!this.isJoined) {
            const data = await this.$store.dispatch('api/postGroupJoinPromise', this.joinSubmit)
            console.log(data)
          } else {
            const data = await this.$store.dispatch('api/postGroupUpdateJoinPromise', this.joinSubmit)
            console.log(data)
          }
        });
        this.successData.open = true;
        // await this.$router.push({ name: 'GroupDetail' });
      } else {
        console.log('Validation failed');
        this.scrollToError();
      }
    },
    scrollToError() {
      for (const refName in this.$refs) {
        const validationProvider = this.$refs[refName];
        if (validationProvider.errors?.length > 0) {
          validationProvider.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          break;
        }
      }
    },
    async updateCityList() {
      if(this.countyCode) {
        this.cityList = await this.$store.dispatch('api/getCityListPromise', this.countyCode);
        console.log(this.cityList)
      }
    },
    refreshCityCode() {
      if(this.cityList.find(city => city.towncode === this.joinSubmit.cityCode)) {
        this.joinSubmit.cityCode = '';
      }
    },
    closeAvailableDialog() {
      this.joinFlowData.open = false;
      this.$router.push({ name: 'GroupDetail' });
    },
    closeSuccessDialog() {
      this.successData.open = false;
      this.$router.push({ name: 'GroupDetail' });
    },
    updateFullAddress() {
      const county = this.countyList.find(item => item.countycode01 === this.countyCode)?.countyname || '';
      const city = this.cityList.find(item => item.towncode === this.joinSubmit.cityCode)?.townname || '';
      this.joinSubmit.fullAddress = `${county}${city}${this.joinSubmit.address}`;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.journey-detail-member-join {
  @include smaller-than-large {
    padding-top: 83.59px;
  }
  .btn.common-setting {
    @include bigger-than-large {
      border-radius: 50px;
    }
  }
  .trail-fixed-nav {
    @include smaller-than-large {
      position: fixed;
      top: 70px;
      left: 0;
      z-index: 900;
      width: 100%;
      background: $color-accent;
      .trail-block {
        margin-bottom: 0;
      }
    }
    @include smaller-than-small {
      top: 56px;
    }
    .operating-btn {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      padding: 8px 16px;
    }
    .dropdown-toggle-btn-group {
      position: absolute;
      top: 20px;
      right: 16px;
      .dropdown-toggle {
        &:before, &:after {
          display: none;
        }
        img {
          width: 24px;
          object-fit: cover;
        }
      }
      .dropdown-menu {
        padding: 0;
        .dropdown-item:not(:last-child) {
          box-shadow: 0px -1px 0px 0px #DBDBDB inset;
          padding: 0.5rem 1.5rem;
        }
      }
    }
  }
  .trail-block {
    background: $color-white;
    padding: 20px;
    margin-bottom: 12px;
    @include smaller-than-large {
      padding: 24px 16px 12px;
    }
  }
  .trail-create-btn-group {
    display: flex;
    flex-wrap: nowrap;
    padding: 16px 0;
    .btn {
      width: 100%;
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
    @include smaller-than-large {
      &.bottom-btn {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
        background: $color-white;
        width: 100%;
        padding: 16px;
      }
    }
  }
  .trail-application-nav {
    background: $color-white;
    padding: 16px 0;
    box-shadow: inset 0 -1px 0 $color-accent;
    @include bigger-than-large {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include smaller-than-large {
      box-shadow: none;
    }
  }
  .trail-team-group {
    background: $color-white;
    padding: 0 36px 12px;
    @include smaller-than-large {
      padding: 0 8px 12px;
    }
    &.join {
      display: flex;
      flex-direction: column;
      // align-items: center;
      padding: 36px;
      @include bigger-than-large {
        padding-left: 150px;
        padding-right: 150px;
      }
      @include smaller-than-large {
        padding: 10px 4px 90px;
      }
    }
    hr {
      margin-top: 48px;
      margin-bottom: 48px;
      border-top: 1px solid $color-black-200;
    }
    .team-members-number {
      color: $color-bk-tp-85;
      padding: 36px 0;
      &.with-hr:not(:last-child) {
        border-bottom: 1px solid $color-black-200;
      }
      .no-permission {
        color: $color-bk-tp-25;
      }
      .avatar-group {
        flex: 0 0 auto;
        margin-right: 36px;
        .edit-link {
          text-align: center;
          margin-top: 8px;
        }
      }
    }
    .avatar {
      flex: 0 0 auto;
    }
    .detail-list {
      flex: 1 1 auto;
      //height: 62px;
      .detail-item {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 12px;
        .item-title {
          flex: 0 0 auto;
          color: $color-bk-tp-25;
          padding-right: 12px;
        }
        .item-desc {
          flex: 1 1 auto;
          word-wrap: break-word;
        }
        .success-alert {
          position: absolute;
          left: 500%;
          white-space: nowrap;
          opacity: 0;
          //transform: translateY(-50%);
          &:before {
            content: '複製成功';
            color: $color-white;
            background: $color-black-800;
            font-size: $font-extra-small;
            padding: 3px 10px 3px;
            opacity: .7;
          }
        }
        .success-alert.active {
          top: -120%;
          left: 0;
          animation: alert 2s;
        }
      }
    }
  }
  .trail-joining-block {
    // max-width: calc(100% / 3);
    .attention-text {
      display: inline-block;
      color: $color-bk-tp-85;
      background: $color-primary-darkyellow;
    }
    @include smaller-than-large {
      max-width: 100%;
    }
  }
  .custom-select, .form-birthday {
    width: 100%;
    height: 46.4px;
    background: #FFFFFF;
    border: 0;
    border-radius: 4px;
    padding: 12px 16px;
    box-shadow: 0 0 0 4px rgba(105, 105, 105, 0.2);
    &:focus {
      border: 2px solid $color-black-800;
    }
  }
}
</style>
