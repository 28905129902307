<template>
  <div class="custom-message-dialog custom-z-index">
    <transition name="fade">
      <div v-if="dialogData.open" class="dialog-content" :class="typeClass">
        <div class="dialog-icon">
          <Icon name="error" size="20" />
        </div>
        <span v-text="dialogData.message"></span>
        <div class="close-btn" @click="closeClicked">
          <Icon name="close" size="20" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'CustomConfirmModal',
  data() {
    return {
    }
  },
  props: {
    dialogData: {
      type: Object,
      default: () => ({
        open: false,
        message: '',
        type: '',
      })
    },
    autoClose: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    typeClass() {
      switch (this.dialogData.type) {
        case 'success':
          return 'success';
        case 'warning':
          return 'warning';
        default:
          return '';
      }
    }
  },
  watch: {
    'dialogData.open'(newVal) {
      if (newVal && this.autoClose) {
        this.startAutoCloseTimer();
      }
    }
  },
  methods: {
    closeClicked() {
      this.$emit('close')
    },
    startAutoCloseTimer() {
      setTimeout(() => {
        this.closeClicked();
      }, 5000);
    },
  }

}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.custom-message-dialog {
  .dialog-content {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    color: $color-dark;
    background: $color-white;
    border: none;
    border-radius: .5rem;
    padding: 1.25rem 1.5rem;
    @include smaller-than-large {
      position: fixed;
      bottom: 100px;
      width: calc(100% - 1.5rem);
    }
    @include bigger-than-large {
      margin-bottom: 1rem;
    }
    &.success {
      color: $color-white;
      background: $color-success-green;
    }
    &.warning {
      color: $color-white;
      background: $color-warning-red;
    }
    span {
      flex-grow: 1;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "src/assets/scss/basic";
.custom-message-dialog {
  .dialog-content {
    .dialog-icon, .close-btn {
      flex-shrink: 0;
    }
  }
}
</style>
